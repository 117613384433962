/* App.css */
html,
body {
  margin: 0;
  padding: 0;
}

.swipe-buttons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.swipe-button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 4px 8px;
  margin: 0 8px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  border: 1px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.swipe-button:hover {
  background-color: #ff0000;
}
/* Style for copyright statement */
.copyright {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 80px;
  width: full;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
}

/* Style for YouTubePlayer component */
.YouTubePlayer {
  position: relative;
  width: 100%;
  height: 0;
   /* Adjust this value as needed for the desired video size */
}

.YouTubePlayer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
